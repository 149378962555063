import React from 'react'
import { graphql } from 'gatsby'
import { css } from 'theme-ui'
import Header from './../components/templateElements/Header'
import BlogCardList, { Blog } from './../components/templateElements/BlogCardList'
import PageIndicator from './../components/templateElements/PageIndicator'
import { LayoutContentWrapper } from './../components/layout'
import config from '../config'
const { blogCategorys } = config

export type CategoryProps = {
  pageContext: {
    category: string
    numPages: number
    currentPage: number
  }
  data: {
    blogs: {
      totalCount: number
      edges: Array<{
        node: Blog
      }>
    }
  }
}

export default function Category({ pageContext, data }: CategoryProps) {
  const { category, numPages, currentPage } = pageContext
  const { edges, totalCount } = data.blogs

  const currentCategory = blogCategorys.find((cate) => cate.value === category)

  return (
    <>
      <Header label={currentCategory?.label || ''} totalCount={totalCount} />
      <LayoutContentWrapper>
        <div css={css({ pt: '2rem' })}>
          <BlogCardList blogs={edges.map(({ node }) => node)} />
          <PageIndicator
            currentPage={currentPage}
            numPages={numPages}
            prevPath={
              currentPage > 1
                ? currentPage === 2
                  ? `/blog/category/${category}`
                  : `/blog/category/${category}/${currentPage - 1}`
                : null
            }
            nextPath={
              currentPage < numPages ? `/blog/category/${category}/${currentPage + 1}` : null
            }
          />
        </div>
      </LayoutContentWrapper>
    </>
  )
}

export const pageQuery = graphql`
  query($category: String, $skip: Int!, $limit: Int!) {
    blogs: allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { category: { in: [$category] } } }
      limit: $limit
      skip: $skip
    ) {
      totalCount
      edges {
        node {
          id
          fields {
            slug
          }
          excerpt(truncate: true, pruneLength: 80)
          timeToRead
          frontmatter {
            title
            tags
            date(fromNow: true)
            overview
            banner {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
